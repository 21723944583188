<template>
  <div class="text-right box">
    <!-- <div class="dflexs head">
            <img class="returns" @click='returnBtn' src="../../assets/guild/returnimg.png" alt="">
            <div class="pagetitle">{{ $t('Withdrawal') }}</div>
        </div> -->
    <div class="text">
      <p>{{ $t("eventRewards.txt1") }}</p>
      <p>{{ $t("eventRewards.txt2") }}</p>
      <p>{{ $t("eventRewards.txt3") }}</p>
      <p>{{ $t("eventRewards.txt4") }}</p>

      <table border="1" cellspacing="0" width="100%">
        <tr>
          <td class="td">{{ $t("eventRewards.player") }}</td>
          <td class="td">{{ $t("eventRewards.proportion") }}</td>
        </tr>
        <tr>
          <td>1~2</td>
          <td>12%</td>
        </tr>
        <tr>
          <td>3~5</td>
          <td>18%</td>
        </tr>
        <tr>
          <td>6~8</td>
          <td>21%</td>
        </tr>
        <tr>
          <td>9~11</td>
          <td>24%</td>
        </tr>
        <tr>
          <td>12~14</td>
          <td>27%</td>
        </tr>
        <tr>
          <td>15~17</td>
          <td>30%</td>
        </tr>
        <tr>
          <td>18~20</td>
          <td>33%</td>
        </tr>
        <tr>
          <td>21~23</td>
          <td>36%</td>
        </tr>
        <tr>
          <td>24~26</td>
          <td>39%</td>
        </tr>
        <tr>
          <td>27~29</td>
          <td>42%</td>
        </tr>
        <tr>
          <td>30~32</td>
          <td>45%</td>
        </tr>
        <tr>
          <td>33~35</td>
          <td>48%</td>
        </tr>
        <tr>
          <td>36~38</td>
          <td>51%</td>
        </tr>
        <tr>
          <td>39~41</td>
          <td>54%</td>
        </tr>
        <tr>
          <td>42~44</td>
          <td>57%</td>
        </tr>
        <tr>
          <td>45+</td>
          <td>60%</td>
        </tr>
      </table>
      <p>{{ $t("eventRewards.txt5") }}</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: [],
    };
  },
  created() {
    this.$completes.complete("getUserInfo");
    window.getUserInfo = this.getUserInfo
    window['getUserInfo'] = (data) => {
        this.getUserInfo(data);
    }
  },
  mounted() {},
  methods: {
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      let langs = this.userInfo.lang.substring(0, 2)
      let lang = this.userInfo.lang
      if (langs == "zh") {
        if (lang == "zh_TW" || lang == "zh_HK") {
          this.$i18n.locale = "tc";
        } else {
          this.$i18n.locale = "zh";
        }
      } else if (langs == "en") {
        this.$i18n.locale = "en";
      } else if (langs == "ar") {
        this.$i18n.locale = "ar";
      } else if (langs == "tr") {
        this.$i18n.locale = "tr";
      } else if (langs == "es") {
        this.$i18n.locale = "es";
      } else if (langs == "pt") {
        this.$i18n.locale = "pt";
      } else {
        this.$i18n.locale = "en";
      }
      document.title = this.$t("eventRewards.title");
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

.box {
  height: 100%;
  display: flex;
  flex-direction: column;
}

html[lang="ar"] .text p {
  direction: rtl;
}
html[lang="ar"] .td {
  direction: rtl;
}


.text {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  padding: 20px 18px;
}

.text p {
  line-height: 30px;
  margin-top: 20px;
}
table {
  line-height: 25px;
  text-align: left;
  border: 1px solid #ccc;
}
td {
  width: 50%;
  padding-left: 5px;
  border: 1px solid #ccc;
}
</style>
